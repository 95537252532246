import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="पांडुरंग शास्त्री आठवले यांनी लिहिलेल्या पत्राचे उत्तर" />
        <HeaderGeneric title="इतर साहित्य" adhyaay='पांडुरंग शास्त्री आठवले यांनी लिहिलेल्या पत्राचे उत्तर'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            २७ जुलै, १९९६< br />
            < br />
प. पू. गुरू दादा यांसी आम्हां उभयतांचे व मुलांचे कृ. शि. सा. न.< br />
< br />
आपली प्रकृती बरी नव्हती, आपले व सौ. मामींचे ऑपरेशन झाले तेही कळले. त्यामुळे आपण चि. देवेंद्रच्या विवाहास उपस्थित राहू शकला नाहीत ह्या संबधीचे पत्र आपण स्वतः आम्हाला पाठविले, इतुकेच नसुन चि. देवेंद्र व सौ. पल्लवी (सुनबाई) ह्यांचे वैवाहीक जीवन धर्माचरणाचे जावो ह्या साठी आपण भगवंतापाशी केलेली प्रार्थना ऐकुन आमच्या उभयतांचे अंतःकरण भरून आले व डोळ्यात आनंदाश्रु मावेनासे झाले. दादा! माणूस आत्मीयतेने जगावा हे आपण आम्हाला वेळोवेळी कृती, भक्ती द्वारा पटविले. खरोखर धन्य आहे तुमची. आपणच हा सर्व पसारा पेलू जाणे. ईश्वर सदैव आपल्या पाठीशी आहे असे म्हणण्यापेक्षा ईश्वरी तत्व, परमात्मा तत्व आपल्या रोमारोमात भरले आहे. हाच तो परमेश्वर, हाच तो विश्ववंदनीय गुरू.< br />
< br />
आपण विश्ववंदनीय गुरू झाले नसुन आपण मुळातच, जन्मतःच विश्ववंदनीय गुरू आहात. सामाजाला, विश्वाला पटायला वेळ लागला.< br />
< br />
आपण चराचराचे प्रेमे द्यायला नि घ्यायलाच जन्मला आहात हे आपल्या चराचरावरील नितांत प्रेमाच्या कृतीतुन सदैव व्यक्त होत असते.< br />
< br />
“वाचा आणि वाचवा” हा आदर्श आपल्या गाढ अभ्यासातून, क्रियाशील, गतीशील जीवनातून पटतो.< br />
< br />
२७ जुलै १९८५ शनीवारी आपण आमच्या घरी येऊन मला घट्ट मिठी मारून माझ्या पाठीवरून हात फिरवून, अगदी थोपटून, जे काही मला आश्वासन दिले - की फडके तुम्ही माझे आहात. तुमचे आणि माझे, दोघांचे, अंतःकरण भरून भरून आले. त्यात आपण व्यक्त केलेला आशीर्वाद म्हणजे नुसती दुधात साखर नसुन पंचामृतच होय. अहो नुसते अमृत प्राप्त होणे कठीण, हे तर आपण मला पंचामृत एकट्यालाच नव्हे तर सर्वांना दिले. केवढी आपल्या हृदयाची विशालता.< br />
< br />
आपल्या हृदयातील विशालतेची थोरवी काय गाऊ? अहो सारे विश्वच आपण व्यापले! आपण जर सर्वव्यापी असल्यावर मी बाजूस कसा राहाणार? द्वैत कसे नांदणार? अद्वैतच नांदणार. अद्वैत असल्यावर मी स्वतंत्रपणे वर्णन कसा करणार?< br />
< br />
आपल्या भेटीत आत्म परमात्न तत्वाची प्रचिती येते ही साक्षीत्वाची खूण आहे.< br />
< br />
आपण पूर्णानंदी आहात. आपणच सच्चिदानंद आहात.< br />
< br />
दादा! गुरू पोर्णिमा जवळ आली आहे असे कसे म्हणू? मला रोजच आपण मानसपूजेत भेटता, पोर्णिमेचा आनंद देता. आपण झोपाळ्यावर बसला आहात, मी आपल्या चरणाशी आहे. ह्या रोजच्या मानसपूजेत किती आनंद मिळत असेल सांगु? आपण सर्वज्ञ आहात.< br />
< br />
हेच पत्री अ.सौ. मामींना कृ. शि. सा. न. परिवारास कृ. स. न.< br />
< br />
आपला नम्र< br />
श्रीकांत यशवंत फडके< br />
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
